import React from 'react';
import DevelopmentItem from '../components/development_item';
import DevelopmentItemConfig from '../configs/development_item_config';

const Development = ({ pageData }) => {
  const { title } = { ...pageData };

  return (
    <div className='development-container' id='development'>
      <div className='animated animate__animated animate__fadeIn'>
        <h1>{title}</h1>
        <div className='item-container'>
          {DevelopmentItemConfig.map((item) => {
            return (
              <DevelopmentItem
                key={item.title}
                data={item}
                className='develop-item'
              ></DevelopmentItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Development;
