import 'animate.css';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const DefaultPage = ({ pageData }) => {
  const [mql, setMql] = useState(false);
  const {
    title,
    subtitle,
    descriptions,
    contentImagePath,
    contentOrientation,
    imgWidth,
    mobileImgWidth,
    objectFit,
  } = {
    ...pageData,
  };

  useEffect(() => {
    setMql(
      window.matchMedia(`screen and (max-width: 815px`).matches || isMobile
    );
    window.addEventListener('resize', () => {
      setMql(
        window.matchMedia(`screen and (max-width: 815px`).matches || isMobile
      );
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const getSize = () => {
    if (imgWidth || mobileImgWidth) {
      return mql ? mobileImgWidth : imgWidth;
    }
    return '100%';
  };

  return (
    <div
      className={'default-page-container ' + title}
      id={title}
      style={{
        flexDirection: `${contentOrientation}`,
      }}
    >
      <div className='text-content animate__animated animate__fadeIn'>
        <h1>{subtitle?.toUpperCase() || title.toUpperCase()}</h1>
        {descriptions.map((description, i) => (
          <p key={i}>{description}</p>
        ))}
      </div>
      {contentImagePath && (
        <div className='image-content'>
          <img
            style={{
              width: `${getSize()}`,
              height: `${getSize()}`,
              objectFit: objectFit ?? 'cover',
            }}
            src={contentImagePath}
            alt='title'
          />
        </div>
      )}
    </div>
  );
};

export default DefaultPage;
