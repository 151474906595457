import React from 'react';

const Hero = () => {
    return (
        <div id="hero" className="hero-container">
            <video className="hero-video" autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/hero-video.mp4'} type="video/mp4" />
            </video>
            <div className="image-container">
                <img
                    src={process.env.PUBLIC_URL + '/images/KenguTech_logo_shadow.png'}
                    alt="kengutech logo"
                    className="hero-logo"
                />
            </div>
        </div>
    );
};

export default Hero;
