import React from 'react'

const MobileHero = () => {
  return (
    <div id="hero" className="mobile-hero-container hero-container">
        <div className="image-container">
            <img
                src={process.env.PUBLIC_URL + '/images/hero-logo-min.png'}
                alt="kengutech logo"
                className="hero-logo"
              />
              <img src={process.env.PUBLIC_URL + '/images/bg-arrow-min.png'} alt="arrows" className='hero-arrow' />
        </div>
    </div>
  )
}

export default MobileHero
