import React from 'react';

const DevelopmentItem = ({ data }) => {
    const { imgPath, title, description } = { ...data };

    return (
        <div className="development-item-container">
            <img className="main-image" src={imgPath} alt="title"></img>
            <h4>{title}</h4>
            <p>{description}</p>
            <img className="stars" src={process.env.PUBLIC_URL + '/images/stars.png'} alt="stars"></img>
        </div>
    );
};

export default DevelopmentItem;
