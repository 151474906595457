const DevelopmentItemConfig = [
    {
        imgPath: process.env.PUBLIC_URL + '/images/devops-min.png',
        title: 'DEVOPS',
        description:
            'We have experience with several DevOps tools. We use Docker containers with Kubernetes clusters in cloud environments like Heroku, AWS, Azure or Digital Ocean.',
    },
    {
        imgPath: process.env.PUBLIC_URL + '/images/backend-min.png',
        title: 'BACKEND',
        description:
            'We develop highly scalable backend services using Java/Kotlin or Node.js stack with the most popular frameworks like Spring Boot, Quarkus, NestS, and Express.',
    },
    {
        imgPath: process.env.PUBLIC_URL + '/images/frontend-min.png',
        title: 'FRONTEND',
        description:
            'On the frontend side we made many web, desktop and mobile applications using the most efficient JavaScript frameworks and libraries such as Angular, React, Vuejs or Next.is',
    },
];

export default DevelopmentItemConfig;
