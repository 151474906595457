const SiteConfig = [
  {
    title: 'architecting',
    descriptions: [
      'We architect the overall design of software systems, ensuring that they are scalable, maintainable and meet the needs of our partners. We believe effective software architecture is crucial to the success of any software project. This is because it helps ensure that the system is easy to understand, modify, and extend, and that it can adapt to changing requirements and technologies. It also plays a key role in reducing development costs and improving the reliability and performance of a system.',
    ],
    contentImagePath: process.env.PUBLIC_URL + '/images/architecting-bg.jpg',
    contentOrientation: 'row',
  },
  {
    title: 'testing',
    descriptions: [
      'It is our goal to provide the highest quality software, which is why testing is so critical to us. Effective software testing is essential for ensuring the quality and reliability of software systems. We therefore perform different types of testing during the development process, including unit testing, integration testing, system testing, and acceptance testing.',
    ],
    contentImagePath: process.env.PUBLIC_URL + '/images/testing-bg.jpg',
    contentOrientation: 'row-reverse',
  },
  {
    title: 'outsourcing',
    subtitle: 'outsourcing & recruitment',
    descriptions: [
      'To help your business achieve your goals, we are always looking for talented and open-minded professionals. Due to our years of experience as developers and devops professionals, we are capable of identifying real knowledge to hire.',
      "It's no longer necessary to waste your time with unqualified recruitment agencies.",
    ],
    contentImagePath: process.env.PUBLIC_URL + '/images/recruitment-min.png',
    backgroundPath: process.env.PUBLIC_URL + '/images/outsourcing-bg.jpg',
    contentOrientation: 'row',
    imgWidth: '70%',
    mobileImgWidth: '300px',
    objectFit: 'contain',
  },
];

export default SiteConfig;
