import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Menu from './components/menu';
import MobileMenu from './components/mobile_menu';
import MobileMenuBar from './components/mobile_menu_bar';
import SiteConfig from './configs/site_config';
import Contact from './pages/contact';
import DefaultPage from './pages/default-page';
import Development from './pages/development';
import Hero from './pages/hero';
import MobileHero from './pages/mobile-hero';

function App() {
  const [mql, setMql] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    setMql(
      window.matchMedia(`screen and (max-width: 815px`).matches || isMobile
    );
    window.addEventListener('resize', () => {
      setMql(
        window.matchMedia(`screen and (max-width: 815px`).matches || isMobile
      );
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const config = SiteConfig;
  return mql ? (
    <div className='App'>
      <MobileMenuBar
        toggleMobileMenu={toggleMobileMenu}
        isOpen={showMobileMenu}
      ></MobileMenuBar>
      {showMobileMenu && (
        <MobileMenu toggleMobileMenu={toggleMobileMenu}></MobileMenu>
      )}
      <MobileHero></MobileHero>
      <Development pageData={{ title: 'DEVELOPMENT' }}></Development>
      {config.map((item) => {
        return <DefaultPage pageData={item} key={item.title}></DefaultPage>;
      })}
      <Contact></Contact>
    </div>
  ) : (
    <>
      <Routes className='App'>
        <Route path='/' element={<Menu />}>
          <Route index element={<Hero />} />
          <Route
            path='development'
            element={<Development pageData={{ title: 'DEVELOPMENT' }} />}
          />
          {config.map((item) => {
            return (
              <Route
                path={item.title}
                key={item.title}
                element={<DefaultPage key={item.title} pageData={item} />}
              />
            );
          })}
          <Route path='contact' element={<Contact />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
