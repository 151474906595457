import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';

const MobileMenuBar = ({ toggleMobileMenu, isOpen }) => {
  return (
        <>
            <div className="menu-container">
              {isOpen ? <CloseIcon className="hamburger-icon" onClick={toggleMobileMenu}></CloseIcon>
                  : <MenuIcon className="hamburger-icon" onClick={toggleMobileMenu}></MenuIcon>}
            </div>
        </>
    );
}

export default MobileMenuBar
