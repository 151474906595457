import { Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#ffffff',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
  },
});

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const contacts = [
    { icon: 'e-mail', contact: 'moc.hcetugnek@ofni' },
    { icon: 'phone', contact: '+36 70 640 5777' },
    { icon: 'website', contact: 'kengutech.com' },
    { icon: 'location', contact: '2. door, 8 Boglárka street, Martonvásár, 2462' },
  ];
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Firstname is required.'),
    lastName: yup.string('Enter lastname.').required('Lastname is required.'),
    phone: yup
      .string()
      .required('Phone number is required.')
      .matches(phoneRegExp, 'Phone number is not valid'),
    subject: yup.string('Enter subject.').required('Subject is required.'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    emailMessage: yup.string('Enter message').required('Message is required'),
  });

  const sendMail = (values) => {
    let data = new FormData();

    data.append('firstName', values.firstName);
    data.append('lastName', values.lastName);
    data.append('email', values.email);
    data.append('subject', values.subject);
    data.append('message', values.emailMessage);
    data.append('phone', values.phone);

    let config = {
      method: 'post',
      url: 'https://kengutech.com/mail/contact.php',
      data: data,
    };

    axios(config)
      .then(() => {
        formik.resetForm();
        setIsOpen(true);
      })
      .catch(function (error) {
        console.log('error: ', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      subject: '',
      emailMessage: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendMail(values);
    },
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <div
      className='contact-container'
      id='contact'
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + '/images/contact-bg.jpg'
        })`,
      }}
    >
      <div className='left-side'>
        <div>
          <h1>GET IN TOUCH</h1>
          <p>
            We are available to assist you and provide answers to any questions
            you may have. We will make an effort to respond to your inquiries
            within a maximum of 48 hours.
          </p>
        </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2705.2227701339007!2d18.792535813090666!3d47.309986371043955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741fd4f502fb9eb%3A0xdeb6949ab4f4d3b8!2zTWFydG9udsOhc8OhciwgQm9nbMOhcmthIHUuIDgsIDI0NjI!5e0!3m2!1shu!2shu!4v1727811533733!5m2!1shu!2shu"
            width='100%'
            height='100%'
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='google maps'
        ></iframe>
        <div className='contacts'>
          {contacts.map((item) => {
            return (
                <div className='contact-item' key={item.contact}>
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          '/images/icon-' +
                          item.icon +
                          '.png'
                      }
                      alt={item.icon}
                  />
                  <p className={item.icon === 'e-mail' ? 'bidi-email' : ''}>
                    {item.contact}
                  </p>
                </div>
            );
          })}
        </div>
      </div>
      <div className='right-side'>
        <ThemeProvider theme={inputTheme}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='input-row double'>
              <div className='input-field'>
                <TextField
                    className={
                        'contact-field form-control' +
                        (formik.errors.firstName && formik.touched.firstName
                            ? ' is-invalid'
                      : '')
                  }
                  id='firstName'
                  name='firstName'
                  label='Firstname'
                  variant='standard'
                  required
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#5a5959',
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottomColor: '#5a5959',
                    },
                  }}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helpertext={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <div className='invalid-feedback'>
                  {formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : null}
                </div>
              </div>
              <div className='input-field'>
                <TextField
                  className={
                    'contact-field form-control' +
                    (formik.errors.lastName && formik.touched.lastName
                      ? ' is-invalid'
                      : '')
                  }
                  id='lastName'
                  name='lastName'
                  label='Lastname'
                  variant='standard'
                  required
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#5a5959',
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottomColor: '#5a5959',
                    },
                  }}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helpertext={formik.touched.lastName && formik.errors.lastName}
                />
                <div className='invalid-feedback'>
                  {formik.errors.lastName && formik.touched.lastName
                    ? formik.errors.lastName
                    : null}
                </div>
              </div>
            </div>
            <div className='input-row double'>
              <div className='input-field'>
                <TextField
                  className={
                    'contact-field form-control' +
                    (formik.errors.email && formik.touched.email
                      ? ' is-invalid'
                      : '')
                  }
                  id='email'
                  name='email'
                  label='Email'
                  variant='standard'
                  required
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#5a5959',
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottomColor: '#5a5959',
                    },
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helpertext={formik.touched.email && formik.errors.email}
                />
                <div className='invalid-feedback'>
                  {formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null}
                </div>
              </div>
              <div className='input-field'>
                <TextField
                  className={
                    'contact-field form-control' +
                    (formik.errors.phone && formik.touched.phone
                      ? ' is-invalid'
                      : '')
                  }
                  id='phone'
                  name='phone'
                  label='Phone Number'
                  variant='standard'
                  type='tel'
                  required
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#5a5959',
                    },
                    '& .MuiInput-underline:hover:before': {
                      borderBottomColor: '#5a5959',
                    },
                  }}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helpertext={formik.touched.phone && formik.errors.phone}
                />
                <div className='invalid-feedback'>
                  {formik.errors.phone && formik.touched.phone
                    ? formik.errors.phone
                    : null}
                </div>
              </div>
            </div>
            <div className='input-field'>
              <TextField
                className={
                  'contact-field form-control' +
                  (formik.errors.subject && formik.touched.subject
                    ? ' is-invalid'
                    : '')
                }
                id='subject'
                name='subject'
                label='Subject'
                variant='standard'
                required
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#5a5959',
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#5a5959',
                  },
                }}
                fullWidth
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helpertext={formik.touched.subject && formik.errors.subject}
              />
              <div className='invalid-feedback'>
                {formik.errors.subject && formik.touched.subject
                  ? formik.errors.subject
                  : null}
              </div>
            </div>
            <div className='input-field'>
              <TextField
                className={
                  'contact-field form-control' +
                  (formik.errors.emailMessage && formik.touched.emailMessage
                    ? ' is-invalid'
                    : '')
                }
                id='emailMessage'
                name='emailMessage'
                label='Message'
                variant='standard'
                fullWidth
                multiline
                rows={4}
                required
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#5a5959',
                  },
                  '& .MuiInput-underline:hover:before': {
                    borderBottomColor: '#5a5959',
                  },
                }}
                value={formik.values.emailMessage}
                onChange={formik.handleChange}
                error={
                  formik.touched.emailMessage &&
                  Boolean(formik.errors.emailMessage)
                }
                helpertext={
                  formik.touched.emailMessage && formik.errors.emailMessage
                }
              />
              <div className='invalid-feedback'>
                {formik.errors.emailMessage && formik.touched.emailMessage
                  ? formik.errors.emailMessage
                  : null}
              </div>
            </div>
            <Button color='primary' variant='contained' fullWidth type='submit'>
              Send email
            </Button>
          </form>
        </ThemeProvider>
      </div>
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        message='Email was sent!'
        onClose={handleClose}
      />
    </div>
  );
};

export default Contact;
