import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import MenuItemsConfig from '../configs/menu-items-config';
const Menu = () => {
  return (
    <>
      <div className='menu-container'>
        <Link to='/'>
          <img
            className='nav-logo'
            src={process.env.PUBLIC_URL + '/images/nav-logo-min.png'}
            alt='kengutech logo'
          ></img>
        </Link>
        <div className='menu-item-container'>
          <div className='left-side'>
            {MenuItemsConfig.map((item) => {
              return (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'menu-item active' : 'menu-item'
                  }
                  to={'/' + item.url}
                  key={item.url}
                >
                  {item.url.toUpperCase()}
                </NavLink>
              );
            })}
          </div>

          <div className='right-side'>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'menu-item active' : 'menu-item'
              }
              to='/contact'
              key='contact'
            >
              CONTACT
            </NavLink>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Menu;
