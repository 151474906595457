import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import MenuItemsConfig from '../configs/menu-items-config';

const MobileMenu = ({ toggleMobileMenu }) => {
  return (
    <>
      <div className='mobile-menu-container'>
        {MenuItemsConfig.map((item) => {
          return (
            <Link
              className='menu-item'
              to={'/#' + item.url}
              key={item.url}
              onClick={toggleMobileMenu}
            >
              {item.url.toUpperCase()}
            </Link>
          );
        })}
        <Link
          className='menu-item'
          to='/#contact'
          key='contact'
          onClick={toggleMobileMenu}
        >
          CONTACT
        </Link>
      </div>
    </>
  );
};

export default MobileMenu;
